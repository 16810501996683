<template>
	<div>
		<!--section banner-->
		<section class="service_banner marginTop_fixed color5">
			<div class="container">
				<div class="row">
					<div class="col-md-12">
						<h1>
							<svg
								width="35"
								height="35"
								viewBox="0 0 35 35"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g clip-path="url(#clip0_1_4182)">
									<path
										d="M33.0052 6.10481L33.0066 6.10618C33.7158 6.81349 33.7171 7.94687 32.9692 8.65912L32.9563 8.67138L32.9437 8.68395L28.1677 13.4594L21.5342 6.77889L26.3157 1.99629L26.3158 1.99613C27.0272 1.28443 28.1844 1.28403 28.8968 1.99641L33.0052 6.10481ZM3.23547 25.0833L17.9342 10.3846L24.6177 17.075L9.91819 31.7746L1.56301 33.4456L3.23547 25.0833ZM10.0261 31.753C10.0262 31.753 10.0262 31.753 10.0263 31.753L10.0261 31.753ZM3.15787 25.1609C3.15773 25.1611 3.15759 25.1612 3.15744 25.1614L2.90026 24.9042L3.15745 25.1613L3.15787 25.1609Z"
										stroke="#EF8451"
										stroke-width="2.91667"
									/>
									<path
										d="M15.5555 34.0273H35"
										stroke="#EF8451"
										stroke-width="2.91667"
									/>
								</g>
								<defs>
									<clipPath id="clip0_1_4182">
										<rect width="35" height="35" fill="white" />
									</clipPath>
								</defs>
							</svg>
							Content Generator
						</h1>
					</div>
				</div>
			</div>
		</section>
		<!--end-->

		<!--image start-->
		<section class="imageBanner">
			<div class="container">
				<div class="row">
					<div class="col-md-12">
						<div class="imgbanner">
							<img src="@/assets/images/img-11.jpg" alt="" />
							<div class="playvideo">
								<a
									href="https://www.youtube.com/watch?v=s8SrerotCV8"
									data-fancybox
								>
									<img src="@/assets/images/play.svg" alt="" /> Play video
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!--end-->

		<!--main section left image-->
		<section class="contentservices">
			<div class="container">
				<div class="row align-items-center">
					<div class="col-md-6">
						<div class="imgservices1">
							<img src="@/assets/images/img-26.png" alt="" />
						</div>
					</div>
					<div class="col-md-6">
						<div class="contentSer">
							<h3>
								Generate <span class="orangecolor">anything</span> you need!
							</h3>
							<p>
								Eget nunc scelerisque viverra mauris in aliquam. Vitae justo
								eget magna fermentum iaculis eu non. Arcu dictum varius duis at
								consectetur lorem donec massa.
							</p>
							<p>
								At quis risus sed vulputate odio ut enim blandit. Elementum
								pulvinar etiam non quam lacus suspendisse faucibus interdum
								posuere. Lorem ipsum dolor sit amet, consectetur adipiscing
								elit, sed do eiusmod tempor incididunt ut labore et dolore magna
								aliqua. Tincidunt nunc pulvinar sapien et. Egestas quis ipsum
								suspendisse ultrices gravida dictum fusce ut.
							</p>
							<p>
								At quis risus sed vulputate odio ut enim blandit. Elementum
								pulvinar etiam non quam lacus suspendisse faucibus interdum
								posuere. At quis risus sed vulputate odio ut enim blandit.
								Elementum pulvinar etiam non quam lacus suspendisse faucibus
								interdum posuere.
							</p>
							<p>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
								eiusmod tempor incididunt ut labore et dolore magna aliqua.
								Tincidunt nunc pulvinar sapien et. Egestas quis ipsum
								suspendisse ultrices gravida dictum fusce ut.
							</p>
							<p>
								At quis risus sed vulputate odio ut enim blandit. Elementum
								pulvinar etiam non quam lacus suspendisse faucibus interdum
								posuere.
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!--end-->

		<!--main section Right  image-->
		<section class="contentservices">
			<div class="container eleven80">
				<div class="row">
					<div class="col-md-6">
						<div class="contentSer">
							<h3>Multiple workflows at once</h3>
							<p>
								It automatically shows you the potential impact for each
								problem, so you can troubleshoot the issue based on how much it
								might affect your rank and how easy it is to implement the
								necessary changes
							</p>
							<p>
								At quis risus sed vulputate odio ut enim blandit. Elementum
								pulvinar etiam non quam lacus suspendisse faucibus interdum
								posuere.
							</p>
							<p>
								Reduce risks and increase efficiency on your website with our
								SEO Suite tool. Our in-depth analysis helps you understand the
								technical flaws on your site, from broken links to missing meta
								tags, so you can improve the performance.
							</p>
							<p>
								At quis risus sed vulputate odio ut enim blandit. Elementum
								pulvinar etiam non quam lacus suspendisse faucibus interdum
								posuere.
							</p>
						</div>
					</div>
					<div class="col-md-6">
						<div class="imgservices2">
							<img src="@/assets/images/img-27.png" alt="" />
						</div>
					</div>
				</div>
			</div>
		</section>
		<!--end-->

		<!--Services Acc-->
		<section class="servicesAccording">
			<div class="container eleven80">
				<div class="row">
					<div class="col-md-12">
						<h3>What Brand Reputation includes?</h3>

						<div class="accordion faqsc" role="tablist">
							<b-card no-body v-for="(items, index) in faqs" :key="items.index">
								<b-card-header header-tag="header" role="tab">
									<b-button
										block
										v-b-toggle="'accordion' + index"
										variant="info"
									>
										{{ items.faqQuestion }}
									</b-button>
								</b-card-header>
								<b-collapse
									:id="'accordion' + index"
									visible
									accordion="my-accordion"
									role="tabpanel"
								>
									<b-card-body>
										<p class="card-text" v-html="items.faqAnswer"></p>
									</b-card-body>
								</b-collapse>
							</b-card>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!--END-->

		<!--Faq services start-->
		<section class="servicesFaq">
			<div class="container eleven80">
				<div class="row">
					<div class="col-md-12">
						<h3>FAQ for Content Generator</h3>

						<h4>What is Content Generator?</h4>
						<p>
							Ultrices tincidunt arcu non sodales neque sodales ut etiam.
							Habitant morbi tristique senectus et netus et. Lacus sed viverra
							tellus in hac habitasse. Sem viverra aliquet eget sit amet. Lorem
							ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
							tempor incididunt ut labore et dolore magna aliqua. Tincidunt nunc
							pulvinar sapien et. Egestas quis ipsum suspendisse ultrices
							gravida dictum fusce ut.
						</p>
						<h4>How is Content Generator?</h4>
						<p>
							Sem viverra aliquet eget sit amet. Lorem ipsum dolor sit amet,
							consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
							labore et dolore magna aliqua. Tincidunt nunc pulvinar sapien et.
							Egestas quis ipsum suspendisse ultrices gravida dictum fusce ut.
						</p>
						<h4>Where is Content Generator?</h4>
						<p>
							Tincidunt nunc pulvinar sapien et. Egestas quis ipsum suspendisse
							ultrices gravida dictum fusce ut. Sem viverra aliquet eget sit
							amet. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
							do eiusmod tempor incididunt ut labore et dolore magna aliqua.
							Ultrices tincidunt arcu non sodales neque sodales ut etiam.
							Habitant morbi tristique senectus et netus et. Lacus sed viverra
							tellus in hac habitasse.
						</p>
					</div>
				</div>
			</div>
		</section>
		<!--end Faq-->

		<!--services hotspots-->
		<section class="servicesFivespots">
			<div class="container">
				<div class="row">
					<div class="col-md-12">
						<h3 class="text-center">Learn about other pillars!</h3>
						<ul class="spotsservices">
							<li class="scolor0">
								<router-link :to="`/${$i18n.locale}/technical-analysis`">
									<svg
										width="34"
										height="36"
										viewBox="0 0 34 36"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M31.9334 10.738L30.8569 8.89906C29.9462 7.34302 27.928 6.80622 26.3453 7.69906C25.5919 8.13599 24.6929 8.25995 23.8465 8.04361C23.0001 7.82727 22.276 7.28839 21.8336 6.54581C21.5491 6.07381 21.3962 5.53621 21.3904 4.98737C21.4161 4.10744 21.0788 3.25474 20.4556 2.62353C19.8323 1.99232 18.976 1.63636 18.0818 1.63672H15.9131C15.037 1.63672 14.1971 1.98037 13.5792 2.59165C12.9612 3.20294 12.6161 4.03145 12.6204 4.89386C12.5944 6.67445 11.1207 8.10444 9.31181 8.10425C8.75431 8.09855 8.20822 7.94802 7.72878 7.66789C6.14602 6.77505 4.12782 7.31185 3.21712 8.86789L2.06151 10.738C1.15192 12.2921 1.68976 14.2777 3.26461 15.1796C4.28829 15.7614 4.91891 16.8367 4.91891 18.0004C4.91891 19.164 4.28829 20.2393 3.26461 20.8211C1.69176 21.7169 1.15333 23.6977 2.06151 25.2471L3.1538 27.1017C3.5805 27.8596 4.29642 28.4189 5.14315 28.6559C5.98988 28.8928 6.8976 28.7877 7.66546 28.364C8.4203 27.9304 9.31981 27.8116 10.164 28.034C11.0083 28.2564 11.7273 28.8016 12.1613 29.5484C12.4458 30.0204 12.5987 30.558 12.6045 31.1069C12.6045 32.9057 14.0858 34.364 15.9131 34.364H18.0818C19.9029 34.364 21.3817 32.9152 21.3904 31.1224C21.3861 30.2573 21.7334 29.4264 22.3548 28.8147C22.9761 28.2029 23.8202 27.8611 24.6989 27.8653C25.2551 27.8799 25.7989 28.0299 26.282 28.3017C27.8606 29.1971 29.8775 28.6676 30.7936 27.1172L31.9334 25.2471C32.3746 24.5016 32.4957 23.6137 32.2699 22.7801C32.044 21.9464 31.49 21.2358 30.7303 20.8056C29.9706 20.3753 29.4165 19.6647 29.1907 18.831C28.9649 17.9974 29.086 17.1095 29.5272 16.364C29.8141 15.8709 30.2294 15.462 30.7303 15.1796C32.2957 14.2782 32.8323 12.3042 31.9334 10.7536V10.738Z"
											stroke="#F5F3EE"
											stroke-width="2.42857"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
										<path
											d="M17.0057 22.4876C19.5237 22.4876 21.5649 20.4781 21.5649 17.9993C21.5649 15.5205 19.5237 13.511 17.0057 13.511C14.4878 13.511 12.4466 15.5205 12.4466 17.9993C12.4466 20.4781 14.4878 22.4876 17.0057 22.4876Z"
											stroke="#F5F3EE"
											stroke-width="2.42857"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>
									<h4>Technical Analysis</h4></router-link
								>
							</li>
							<li class="scolor1">
								<router-link :to="`/${$i18n.locale}/content-analysis`">
									<svg
										width="34"
										height="40"
										viewBox="0 0 34 40"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M9.5 8.5625H24.5M9.5 14.1875H24.5M9.5 19.8125H17.9375M27.3125 24.5H22.625M17.9375 24.5H22.625M22.625 24.5V32.9375M32 5.75V33.875C32 35.9461 30.3211 37.625 28.25 37.625H5.75C3.67893 37.625 2 35.9461 2 33.875V5.75C2 3.67893 3.67893 2 5.75 2H28.25C30.3211 2 32 3.67893 32 5.75Z"
											stroke="#F5F3EE"
											stroke-width="2.8125"
											stroke-linejoin="round"
										/>
									</svg>
									<h4>Content Analysis</h4></router-link
								>
							</li>

							<li class="scolor3">
								<router-link :to="`/${$i18n.locale}/project-management`">
									<svg
										width="37"
										height="39"
										viewBox="0 0 37 39"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M27.25 15.125L18.9348 19.2826C18.6611 19.4195 18.3389 19.4195 18.0652 19.2826L9.75 15.125M27.25 15.125L34.2608 11.6196C34.9774 11.2613 34.9774 10.2387 34.2608 9.88042L18.9348 2.2174C18.6611 2.08054 18.3389 2.08054 18.0652 2.2174L2.73916 9.88042C2.02259 10.2387 2.02259 11.2613 2.73916 11.6196L9.75 15.125M27.25 15.125L34.2608 18.6304C34.9774 18.9887 34.9774 20.0113 34.2608 20.3696L27.25 23.875M9.75 15.125L2.73916 18.6304C2.02259 18.9887 2.02259 20.0113 2.73916 20.3696L9.75 23.875M27.25 23.875L18.9348 28.0326C18.6611 28.1695 18.3389 28.1695 18.0652 28.0326L9.75 23.875M27.25 23.875L34.2608 27.3804C34.9774 27.7387 34.9774 28.7613 34.2608 29.1196L18.9348 36.7826C18.6611 36.9195 18.3389 36.9195 18.0652 36.7826L2.73916 29.1196C2.02259 28.7613 2.02259 27.7387 2.73916 27.3804L9.75 23.875"
											stroke="#F5F3EE"
											stroke-width="2.91667"
										/>
									</svg>
									<h4>Project Management</h4></router-link
								>
							</li>
							<li class="scolor4">
								<router-link :to="`/${$i18n.locale}/brand-reputation`">
									<svg
										width="37"
										height="37"
										viewBox="0 0 37 37"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<circle
											cx="6.125"
											cy="26.75"
											r="4.125"
											stroke="#F5F3EE"
											stroke-width="2.75"
										/>
										<circle
											r="4.125"
											transform="matrix(1 0 0 -1 30.875 26.75)"
											stroke="#F5F3EE"
											stroke-width="2.75"
										/>
										<circle
											r="4.125"
											transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 18.5 6.125)"
											stroke="#F5F3EE"
											stroke-width="2.75"
										/>
										<path
											d="M12.3125 32.9375C12.3125 32.9375 15.0768 35 18.5 35C21.9232 35 24.6875 32.9375 24.6875 32.9375"
											stroke="#F5F3EE"
											stroke-width="2.75"
											stroke-linejoin="round"
										/>
										<path
											d="M30.875 19.5312C30.875 19.5312 30.875 17.4688 29.5312 14.7787C28.1873 12.0886 25.7188 10.25 25.7188 10.25"
											stroke="#F5F3EE"
											stroke-width="2.75"
											stroke-linejoin="round"
										/>
										<path
											d="M6.125 19.5312C6.125 19.5312 6.125 17.4688 7.46883 14.7787C8.81265 12.0886 11.2812 10.25 11.2812 10.25"
											stroke="#F5F3EE"
											stroke-width="2.75"
											stroke-linejoin="round"
										/>
									</svg>

									<h4>Brand Reputation</h4></router-link
								>
							</li>
							<li class="scolor5">
								<router-link :to="`/${$i18n.locale}/crm`">
									<svg
										width="49"
										height="39"
										viewBox="0 0 49 39"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M2 32V7C2 4.23858 4.23858 2 7 2H42C44.7614 2 47 4.23858 47 7V32C47 34.7614 44.7614 37 42 37H7C4.23858 37 2 34.7614 2 32Z"
											stroke="#F5F3EE"
											stroke-width="3.75"
											stroke-linejoin="round"
										/>
										<path d="M2 12H47" stroke="#F5F3EE" stroke-width="3.75" />
										<path
											d="M9.5 19.5L9.5 22.625L9.5 32"
											stroke="#F5F3EE"
											stroke-width="3.75"
										/>
										<path
											d="M42 22L26.375 22L17 22"
											stroke="#F5F3EE"
											stroke-width="3.75"
										/>
										<path
											d="M42 29.5L26.375 29.5L17 29.5"
											stroke="#F5F3EE"
											stroke-width="3.75"
										/>
									</svg>
									<h4>CRM</h4></router-link
								>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</section>
		<!--end-->
		<ServiceCallAction />
	</div>
</template>

<script>
	import MainPage from "@/layouts/MainPage.vue";
	import eng from "../locales/technical_analysis_faq_en.json";
	import ita from "../locales/technical_analysis_faq_it.json";

	import ServiceCallAction from "@/components/Services_Call_Actions.vue";

	export default {
		name: "TechnicalAnalysis",
		components: {
			ServiceCallAction,
		},
		data: () => ({
			eng: eng,
			ita: ita,
		}),
		computed: {
			faqs() {
				return this.$i18n.locale === "en" ? this.eng : this.ita;
			},
		},
		//this for layout call
		created() {
			this.$parent.$emit("update:layout", MainPage);
		},
		//this for layout render
		render() {
			return this.$slots.default[0];
		},

		//Meta info title + description + others meta
		metaInfo() {
			return {
				title: "SEO Suite",
				htmlAttrs: {
					lang: this.$i18n.locale,
				},
				meta: [
					{ name: "description", content: "SEO Suite" },
					{ property: "og:title", content: "SEO Suite" },
					{ property: "og:site_name", content: "SEO Suite" },
					{ property: "og:type", content: "website" },
					{ name: "robots", content: "index,follow" },
				],
			};
		},
		//end meta info
	};
</script>

<style lang="scss" scoped></style>
